import React from 'react';
import '../assets/css/Website.scss';

function About() {
  return (
    <div>
      <h1>About Page</h1>
    </div>
  );
}

export default About;
