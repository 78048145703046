import React from 'react';
import '../assets/css/Website.scss';

function Contact() {
  return (
    <div>
      <h1>Contact</h1>
    </div>
  );
}

export default Contact;
